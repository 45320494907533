import { createTheme } from "@mui/material/styles";

const colors = {
  primary: "#333",
  secondary: "#fff",
  black: "#000",
  info:"#6564ff"
};

export const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: colors.primary
    },
    info: {
      main: colors.info
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    primary: {
      main: colors.black,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.secondary,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: colors.primary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          background: colors.info,
          color: colors.secondary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        background: colors.secondary,
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.secondary,
        },
      },
    },
  },
});