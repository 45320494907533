import { Button, Card, CardContent, Container, Grid, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import './HeroStyles.css'
import logo from '../assest/logo-2.jpeg'
import Lock from '../assest/lock.png'
import { Await, Link } from 'react-router-dom'
import { Config } from '../blockchain/config';
import { useEffect } from 'react';

let walletConnection = false;
let TempValue;

const ZepCoinCalculator = () => {
    const curencySelectRef = useRef()
    const [BtnStatus, setBtnStatus] = useState('Swap');
    const [Approve_Btn, setApprove_Btn] = useState("Approve")
    const [Temp1, setTemp1] = useState('TRON')
    const [Temp2, setTemp2] = useState('GGET')
    const [Temp3, setTemp3] = useState(1)
    const [ApproveStaus, setApproveStaus] = useState(false)
    const [valueGget, setvalueGget] = useState()

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const stylemodal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    


    const handleConvertValue = event => {
        const {value} = event.target;
        const parsed = parseFloat(value);
        TempValue = parsed;
        setTemp3(parsed);
        if(Temp1 === "GGET" && Temp2 === "USDT"){
        setvalueGget(parsed * 0.0610);
        // setApproveStaus("vissible")
        setApproveStaus(true)
        console.log("first coin is GGET And Second One Is USDT")
        }
        if(Temp1 === "TRON" && Temp2 === "USDT"){
            setApproveStaus(true)
        setvalueGget(parsed*0.05426)
        console.log("first coin is Tron And Second One Is USDT")
        }
        if(Temp1 === "USDT" && Temp2 === "GGET"){
            setApproveStaus(true)
        setvalueGget(parsed/ 0.0610)
        console.log("first coin is Usdt And Second One Is Gget")
        }
        if(Temp1 === "USDT" && Temp2 === "TRON"){
            setApproveStaus(true)
        setvalueGget(parsed/ 0.05426)
        console.log("first coin is Usdt And Second One Is Gget")
        }
        if(Temp1 === "GGET" && Temp2 === "TRON"){
            const UsdtToTron = parsed/0.05426;
            const UsdtToGget = parsed/0.0625
            setApproveStaus(true)
        setvalueGget((UsdtToGget/UsdtToTron)*parsed)
        console.log("first coin is GGET And Second One Is Tron")
        }
        if(Temp1 === "TRON" && Temp2 === "GGET"){
            const UsdtToTron = parsed/0.05426;
            const UsdtToGget = parsed/0.06250;
            setApproveStaus(false)
        setvalueGget((UsdtToTron/UsdtToGget)*parsed);
        console.log("first coin is Tron And Second One Is Gget")
        }
       
        
    }

    const SwapHandler = async() => {
        try {
            if (!walletConnection) {
                alert("Connect to the wallet first")
            } else {
                if (ApproveStaus=== true) {
                alert("Approve Your Token First") 
                }else{
                if(Temp1 === "TRON" && Temp2 === "GGET"){
                    const DappContarct = await  window.tronWeb.contract().at(Config.SwapAddress);
                    const Tx = await DappContarct.Buy_through_tron(Temp3).send({
                        feeLimit:100_000_000,
                       callValue:Temp3*10**6,
                       shouldPollResponse:true
                    })
                    alert("purchase Succesfully");
                }if(Temp1 === "GGET" && Temp2 === "TRON"){
                    const SwapContract = await window.tronWeb.contract().at(Config.SwapAddress);
                    const Tx = await SwapContract.sell_through_tron(Temp3).send({
                        feeLimit:100_000_000,
                    });
                   alert("swap Succesfully")
                } 
            }}
        } catch (error) {
            
        }
    }

    const ApproveHandler = async() => {
        if (!walletConnection) {
            alert("Connect to the wallet first")
        } else {
            try {
                if(Temp1 === "GGET" && Temp2 === "TRON"){
                    const GgetContract = await window.tronWeb.contract().at(Config.GgetAddress);
                    const Ad = Config.SwapAddress
                            const Tx = await GgetContract.approve(Ad,Temp3*10**9).send({
                                feeLimit:100_000_000,
                            });
                            setApproveStaus(false);
                            alert("Approved Succesfully now you can swap your token");
                    }
            } catch (error) {
                
            }

        }
        
    }
    

    const ConverterHandler = async() => {
           let TempValue = Temp1;
            setTemp1(Temp2)
            setTemp2(TempValue)
            if(Temp1 === "GGET" && Temp2 === "USDT"){
                setvalueGget(Temp3 / 0.0625)
                console.log("first coin is GGET And Second One Is USDT")
                }
                if(Temp1 === "TRON" && Temp2 === "USDT"){
                setvalueGget(Temp3/0.05426)
                console.log("first coin is Tron And Second One Is USDT")
                }
                if(Temp1 === "USDT" && Temp2 === "GGET"){
                setvalueGget(Temp3 * 0.0610)
                console.log("first coin is Usdt And Second One Is Gget")
                }
                if(Temp1 === "USDT" && Temp2 === "TRON"){
                    setvalueGget(Temp3* 0.05426)
                    console.log("first coin is Usdt And Second One Is Gget")
                    }
                if(Temp1 === "GGET" && Temp2 === "TRON"){
                    const UsdtToTron = Temp3/0.05426;
                    const UsdtToGget = Temp3/0.06250;
                setvalueGget(UsdtToTron/UsdtToGget);
                console.log("first coin is GGET And Second One Is Tron")
                }
                if(Temp1 === "TRON" && Temp2 === "GGET"){
                    const UsdtToTron = Temp3/0.05426;
                    const UsdtToGget = Temp3/0.0625
                setvalueGget(UsdtToGget/UsdtToTron)
                console.log("first coin is Tron And Second One Is Gget")
                }
            
    }

    return (
        <>
            <Card variant='outlined' className='boxdesign'>
                <CardContent>
                    <Typography className='title-3' variant='h3' gutterBottom>
                    GGET EXCHANGE
                    </Typography>
                    <Typography variant="h6" gutterBottom
                        className='boxtitle'
                    >
                        Gagner Tijara Decentrlised Exchange
                    </Typography>
                </CardContent>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography className='price-data' variant='subtitle1' style={{ textAlign: "left" }}>$Live Buying Price</Typography>
                            <TextField value={"0.0625$"} fullWidth variant='filled' disabled
                                className='boxinput'
                                inputProps={{
                                    style: {
                                        background: "white",
                                        padding: "15px",
                                        borderRadius: 10
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='subtitle1' className='price-data' style={{ textAlign: "left" }}>$Live Selling Price</Typography>
                            <TextField value={"0.0610$"} fullWidth variant='filled' disabled
                                inputProps={{
                                    style: {
                                        color: "black",
                                        background: "white",
                                        padding: "15px",
                                        borderRadius: 10
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                            <OutlinedInput
                                // value={Temp1}
                                style={{ backgroundColor: "white", paddingRight: 0 }}
                                onChange={handleConvertValue}
                                type="number"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Select
                                            autoWidth
                                            ref={curencySelectRef}
                                            value={Temp1}
                                            onChange = {event => { setTemp1(event.target.value)}}
                                        >
                                            <MenuItem value={'GGET'}>$GGET</MenuItem>
                                            <MenuItem value={'USDT'}>$USDT</MenuItem>
                                            <MenuItem value={'TRON'}>$TRON</MenuItem>
                                        </Select>
                                    </InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className='converter-icon' >
                                <SwapHorizontalCircleIcon onClick={ConverterHandler} className="swap-icon">
                                </SwapHorizontalCircleIcon>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <OutlinedInput
                                value={valueGget}
                                style={{ backgroundColor: "white", paddingRight: 0 }}
                                // onChange={handleConvertValue}
                                type="number"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Select
                                            autoWidth
                                            ref={curencySelectRef}
                                            value={Temp2}
                                            onChange = {event => { setTemp2(event.target.value)}}
                                        >                 
                                            <MenuItem value={'TRON'}>$TRON</MenuItem>
                                            <MenuItem value={'USDT'}>$USDT</MenuItem>
                                            <MenuItem value={'GGET'}>$GGET</MenuItem>
                                        </Select>
                                    </InputAdornment>}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    {(ApproveStaus)?<Button variant='contained' onClick={ApproveHandler}  className="btn" style={{
                        display: {ApproveStaus},
                        width: "auto",
                        padding: "10px 35px",
                        marginRight: "10px"
                    }}>{Approve_Btn}</Button>:null}
                    <Button variant='contained'  className="btn-right" onClick={SwapHandler} style={{
                        width: "auto",
                        padding: "10px 60px",
                        marginRight: "10px",
                    }}>{BtnStatus}</Button>

                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylemodal}>
                            Hello
                        </Box>
                    </Modal>
                </CardContent>
            </Card>
            <div></div></>
    )
}

;
const Hero = () => {
    const [BtnStatus, setBtnStatus] = useState("CONNECT WALLET")
    const connectWallet = async () => {
        if(window.tronWeb){
        try {
           const ac = await window.tronLink.request({method: 'tron_requestAccounts'})
           console.log(ac)
           const CurrentAc = await window.tronWeb.defaultAddress.base58;
           setBtnStatus("connected to " + CurrentAc.slice(0,7) + " .....")
           walletConnection = true;
        //  SetContarct()
        } catch (error) {
            alert("Unlock Your Wallet");
        }
    }else(
        alert("please install tronlink")
    )
    }

    const SetContarct = async() => {
        const SwapContract = await  window.tronWeb.contract().at(Config.GgetAddress);
        const DappContarct = await  window.tronWeb.contract().at(Config.SwapAddress);
        console.log('contarct at'+ SwapContract);
        const symbol = await SwapContract.name().call();
        const Tx = await DappContarct.Buy_through_tron(1).send({
            feeLimit:100_000_000,
           callValue:1000000,
           shouldPollResponse:true
        })
        alert("purchase Succesfully");
        console.log(symbol);
    }

    return (
        <>

            <header>
                <nav className='navbar'>
                    <div className='logo'>
                        <Link to='/'><img src={logo} alt='' /></Link>
                    </div>
                    <div className='logobtn'>
                        <Button onClick={connectWallet} className="btn"><img src={Lock} alt="lock" />{BtnStatus}</Button>
                    </div>
                </nav>
            </header>
            <div className='hero'>

                <Container maxWidth='xlg'>

                    <div className='content'>
                        <div className='col-2'>
                            <ZepCoinCalculator />
                        </div>

                    </div>
                </Container>

            </div>

        </>
    )
}

export default Hero

